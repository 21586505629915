import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'
import water from '../../images/water.png'
import sugar from '../../images/sugar.png'
import paper from '../../images/paper.png'
import barrel from '../../images/barrel.png'
import molecule from '../../images/molecule.png'
import ecology from '../../images/ecology.png'
import service from '../../images/service-excellence.png'
import delivery from '../../images/swift-delivery-time.png'
import quality from '../../images/quality.png'
import map from '../../images/map.png'
import { Helmet } from 'react-helmet'
import moment from 'moment'

const IndexPageLt = () => (
  <Layout>
    <Helmet>
      <title>IN-ECO Cheminiai Reagentai | www.in-eco.biz/lt</title>
      <meta
        name="description"
        content="IN-ECO - tarptautinis cheminių reagentų gamintojas. Mūsų produktai naudojami pramoninio vandens paruošimo, cukraus gamybos, popieriaus gamybos ir naftos ir dujų gavybos srityse."
      />
    </Helmet>
    <div className="cta">
      <div className="bookmark"></div>
      <div className="columns cta-content">
        <div className="column">
          <h1 className="title title-middle cta-content-title">
            IN-ECO yra cheminių reagentų prekinis ženklas
          </h1>
          <h2 className="subtitle cta-content-subtitle">
            Mūsų reagentai naudojami pramoninėse cheminio apdirbimo programose
          </h2>
          <Link to="/about-us" className="button button-primary">
            Apie mus
          </Link>
        </div>
        <div className="column has-text-right">
          <div className="title cta-years">
            {moment().diff(moment([2009, 4, 13]), 'years')}
          </div>
          <div className="subtitle cta-content-subtitle subtitle-small">
            Metų sėkmingo bendradarbiavimo
            <br /> su mūsų klientais
          </div>
        </div>
      </div>
    </div>
    <div className="columns cta-categories">
      <Link to="/lt/vandens-apdirbimas" className="column cta-categories-item">
        <div className="cta-categories-item-img-container">
          <img src={water} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">
          Cheminis vandens
          <br />
          paruošimas
        </span>
      </Link>
      <Link
        to="/lt/reagentai-cukraus-pramonei"
        className="column cta-categories-item"
      >
        <div className="cta-categories-item-img-container">
          <img src={sugar} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">Cukraus pramonė</span>
      </Link>
      <Link
        to="/lt/reagentai-popieriaus-pramonei"
        className="column cta-categories-item"
      >
        <div className="cta-categories-item-img-container">
          <img src={paper} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">Celiuliozės pramonė</span>
      </Link>
      <Link
        to="/lt/naftos-ir-dujų-gavyba"
        className="column cta-categories-item"
      >
        <div className="cta-categories-item-img-container">
          <img src={barrel} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">
          Naftos ir <br />
          dujų pramonė
        </span>
      </Link>
      <Link to="/lt/purolite" className="column cta-categories-item">
        <div className="cta-categories-item-img-container">
          <img src={molecule} className="cta-categories-item-img" alt="" />
        </div>
        <span className="cta-categories-item-text">Purolite dervos</span>
      </Link>
    </div>
    <div className="advantages">
      <h2 className="title title-large advantages-title">
        IN-<span className="text-accented">ECO</span> sprendimai
      </h2>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            IN-ECO produktai užtikrina aplinkos tausojimą ir tvaresnį gamybos
            procesą pramonėje
          </div>
          <div className="content-text advantages-item-text">
            Mūsų sprendimai padidina įrangos darbo efektyvumą, sumažina
            energijos sanaudas bei aplinkos taršą.
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">Ekologija</div>
            <img src={ecology} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            IN-ECO specialistai yra savo srities ekspertai su gausiomis
            teorinėmis žiniomis ir praktiniais įgūdžiais
          </div>
          <div className="content-text advantages-item-text">
            Profesionalus požiūris užtikrina individualų sprendimą kiekvienam
            klientui
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">Paslaugų kokybė</div>
            <img src={service} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            Aukštą IN-ECO produktų kokybę užtikrina ISO 9001 ir ISO 14001
            sertifikatai bei ilgametė darbuotojų patirtis
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">Kokybės garantas</div>
            <img src={quality} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
      <div className="columns advantages-item">
        <div className="column">
          <div className="content-text advantages-item-text">
            Patogi vieta ir greitas užsakymų vykdymas užtikrina trumpiausią
            produktų pristatymo laiką
          </div>
        </div>
        <div className="column">
          <div className="advantages-item-image-container">
            <div className="advantages-item-title">Operatyvus pristatymas</div>
            <img src={delivery} className="advantages-item-image" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className="location">
      <img src={map} alt="" />
      <h4 className="location-title">
        Lengvai pasiekiami <span className="text-accented">Europoje</span>
      </h4>
    </div>
  </Layout>
)
export default IndexPageLt
